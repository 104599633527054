import * as Sentry from '@sentry/browser'

interface SentryConfig {
  dsn: string
  release: string
}

declare global {
  interface Window {
    sentryConfig: SentryConfig
    sentryRaiseException: CallableFunction
  }
}

if ('sentryConfig' in window) {
  Sentry.init(window.sentryConfig)
}

// This method is used for testing the sentry integration.
window.sentryRaiseException = () => {
  throw new Error('This is a frontend-test exception')
}
